<template>
  <div class="table-container_filter cell">
    <bc-btn
      icon
      class="filter-icon"
      :max-width="30"
      :max-height="30"
      @click="calculatePremiumDiscount"
      :title="
        $t(
          'controlTower.pages.takeUpPremiumDiscountManagement.calculatePremiumDiscount'
        )
      "
    >
      <bc-icon>fas fa-calculator</bc-icon>
    </bc-btn>
  </div>
</template>

<script>
import { BcBtn, BcIcon } from '@brain/ui'
import { eventHub } from '@cargill/shared'
import service from '../../api/takeUpPremiumDiscountManagementService'

export default {
  name: 'TakeUpPremiumDiscountManagementActionsCellRenderer',
  components: { BcBtn, BcIcon },
  data() {
    return {
      rowId: null
    }
  },
  beforeMount() {
    this.rowId = this.params.node.data.id
  },
  methods: {
    async calculatePremiumDiscount() {
      try {
        const result = await service.calculatePremiumDiscountById(this.rowId)
        eventHub.$emit('reload-take-up-premium-discount-data')
        if (result.alertsQty == 0) {
          this.notify.success({
            title: this.$t('application.misc.success')
          })
        } else {
          this.notify.warning({
            title: this.$t(
              'controlTower.pages.takeUpPremiumDiscountManagement.premiumDiscountCalculateResultItemWithAlerts',
              [result.alertsQty]
            )
          })
        }
      } catch (e) {
        console.log(e)
        this.notify.error({
          title: this.$t('application.misc.error')
        })
      }
    },
    reloadTable() {
      const { reloadTable } = this.params.api.context
      if (reloadTable != undefined) {
        reloadTable()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* start scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}
/* end scrollbar*/
</style>
