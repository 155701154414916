<template>
  <bc-select
    class="cg-inline-input-toggle"
    :value="value"
    :items="items"
    :return-object="false"
    @input="$emit('input', $event)"
  >
  </bc-select>
</template>

<script>
import Vue from 'vue'
import { BcSelect } from '@brain/core'

export default Vue.extend({
  name: 'CgInlineInputToggle',
  components: { BcSelect },
  props: {
    value: { type: Boolean, default: false }
  },
  computed: {
    items() {
      return [
        {
          text: this.$t('application.general.true').toLocaleUpperCase(),
          value: true
        },
        {
          text: this.$t('application.general.false').toLocaleUpperCase(),
          value: false
        }
      ]
    }
  }
})
</script>
