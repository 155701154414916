<template>
  <bc-select
    class="cg-inline-input-select"
    v-bind="$attrs"
    v-on="$listeners"
    :value="model"
    :items="items"
    :loading="loading"
    :multiple="type == 'multiselect'"
    @input="model = $event"
  >
  </bc-select>
</template>

<script>
import Vue from 'vue'
import { BcSelect } from '@brain/core'

export default Vue.extend({
  name: 'CgInlineInputSelect',
  components: { BcSelect },
  props: {
    options: { default: () => [], type: [Array, Function] },
    type: { default: 'select', type: String },
    itemData: { default: () => ({}), type: Object },
    value: { default: null }
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  computed: {
    model: {
      get() {
        if (
          this.type === 'multiselect' &&
          this.value &&
          !Array.isArray(this.value)
        ) {
          return [this.value]
        }
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    options: {
      immediate: true,
      async handler() {
        if (typeof this.options === 'function') {
          this.loading = true
          this.items = await this.options({ itemData: this.itemData })
          this.loading = false
        } else {
          this.items = this.options
        }
      }
    }
  }
})
</script>
