<template>
  <bc-text-field class="cg-inline-input-text" v-bind="$attrs" v-on="$listeners">
  </bc-text-field>
</template>

<script>
import Vue from 'vue'
import { BcTextField } from '@brain/core'

export default Vue.extend({
  name: 'CgInlineInputText',
  components: { BcTextField }
})
</script>
