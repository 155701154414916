<template>
  <bc-text-field
    class="cg-inline-input-number"
    v-bind="$attrs"
    v-on="$listeners"
    type="number"
  >
  </bc-text-field>
</template>

<script>
import Vue from 'vue'
import { BcTextField } from '@brain/core'

export default Vue.extend({
  name: 'CgInlineInputNumber',
  components: { BcTextField }
})
</script>
