<template>
  <component
    v-bind="attrs"
    ref="input"
    clear-icon="fa-times"
    class="cg-inline-input"
    autofocus
    :type="type"
    :value="value"
    :options="options"
    :is="inputComponent"
    :input-value="value"
    :item-data="itemData"
    :clearable="isClearable"
    :disabled="editable === false"
    @blur="onBlur"
    @input="value = $event"
    @setFieldError="$emit('setFieldError', $event)"
  ></component>
</template>

<script>
import Vue from 'vue'
import { InputType } from '@brain/core'

import CgInlineInputAutocomplete from './CgInlineInputAutocomplete.vue'
import CgInlineInputDateTime from './CgInlineInputDateTime.vue'
import CgInlineInputText from './CgInlineInputText.vue'
import CgInlineInputNumber from './CgInlineInputNumber.vue'
import CgInlineInputSelect from './CgInlineInputSelect.vue'
import CgInlineInputToggle from './CgInlineInputToggle.vue'

export default Vue.extend({
  name: 'CgInlineInput',
  data() {
    return {
      value: null,
      type: null,
      editable: true,
      options: [],
      attrs: {},
      itemData: {}
    }
  },
  mounted() {
    this.value = this.params.value
    this.type = this.params.type
    this.options = this.params.options
    this.editable = this.params.editable
    this.attrs = this.params.colDef
    this.itemData = this.params.data
  },
  methods: {
    getValue() {
      return this.value
    },
    isCancelBeforeStart() {
      return false
    },
    onBlur() {
      const aggridApi = this.$refs.input?.$parent?.params?.api
      if (aggridApi) {
        const editingCell = aggridApi.getEditingCells()
        if (editingCell.length > 0) {
          aggridApi.stopEditing()
        }
      }
    }
  },
  computed: {
    inputComponent() {
      switch (this.type) {
        case InputType.CHECKBOX:
        case InputType.SWITCH:
          return CgInlineInputToggle
        case InputType.SELECT:
        case InputType.MULTISELECT:
          return CgInlineInputSelect
        case InputType.AUTOCOMPLETE:
          return CgInlineInputAutocomplete
        case InputType.DATETIME:
        case InputType.DATE:
        case InputType.TIME:
          return CgInlineInputDateTime
        case InputType.NUMERIC:
          return CgInlineInputNumber
        case InputType.TEXT:
        default:
          return CgInlineInputText
      }
    },
    isClearable() {
      return !(this.attrs.validations && this.attrs.validations.required)
    }
  }
})
</script>

<style lang="scss" scoped>
.cg-inline-input {
  height: 40px;
  margin: 0;
  padding: 0;
  background: #1f2126;

  ::v-deep {
    .v-input,
    .v-input__control {
      height: 40px;
      padding: 0;
      margin: 0;
    }

    .v-text-field__details {
      display: none;
    }

    .v-input__slot {
      padding-top: 3px;
      margin: 0;

      &::after,
      &::before {
        content: none;
      }

      input {
        padding-left: 19px;
      }
    }

    .v-select__selection--comma {
      padding-left: 19px;
      padding-right: 4px;
      margin: 0;
      max-width: 99%;
    }
  }

  &::v-deep.v-select .v-input__slot {
    flex-wrap: nowrap;
    input {
      padding: 0;
    }
  }

  &::v-deep.v-select .v-select__selections .v-input__slot {
    input {
      max-width: 1%;
    }
  }
}
</style>
