import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'
import { CrudAction } from '@brain/core'

const logRequest = (serviceName, commandName, ...args) => {
  // eslint-disable-next-line no-console
  console.log(
    `%c ${serviceName} %c ${commandName} %c`,
    'background:#21a0e2 ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
    'background:#89cd4d ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
    'background:transparent',
    ...args
  )
}

const baseUrl = '/api/controltower/takeUpPremiumDiscountManagement'

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")
  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null
  return decodeURIComponent(filename)
}

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.contractReference.options = ctx.contractReferenceOptions
  fieldsById.hviAndGradePdReferenceTable.options =
    ctx.hviAndGradePdReferenceTableOptions
  fieldsById.contaminationPdReferenceTable.options =
    ctx.contaminationPdReferenceTableOptions
  fieldsById.pdCurrency.options = ctx.pdCurrencyOptions
}

service.previewWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'previewWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/weightedAveragePreview?${queryParams}`
      : `${baseUrl}/weightedAveragePreview`
    const response = await api.get(endPoint)
    return response.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.applyWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'applyWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/applyWeightedAverage?${queryParams}`
      : `${baseUrl}/applyWeightedAverage`
    await api.put(endPoint)
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.clearWeightedAverage = async (queryParams) => {
  logRequest('P/D', 'clearWeightedAverage', queryParams)
  try {
    const endPoint = queryParams
      ? `${baseUrl}/clearWeightedAverage?${queryParams}`
      : `${baseUrl}/clearWeightedAverage`
    await api.put(endPoint)
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.validateFieldsWithStatusChange = async (entities) => {
  logRequest('P/D', 'validateFieldsWithStatusChange')
  try {
    const response = await api.post(
      `${baseUrl}/validateFieldsWithStatusChange`,
      entities
    )
    return response.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.exportProducer = async (queryParams) => {
  logRequest('P/D', 'exportProducer', queryParams)
  const [key, value] = queryParams.split('=')
  const jsonObject = { [key]: value }

  const filter = jsonObject
  try {
    const endPoint = `${baseUrl}/exportProducer`
    const response = await api.post(endPoint, filter, { responseType: 'blob' })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.calculatePremiumDiscountById = async (id) => {
  logRequest('P/D', 'calculatePremiumDiscountById', id)

  try {
    const response = await api.put(`${baseUrl}/calculatePremiumDiscount/${id}`)
    return response?.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.calculatePremiumDiscountByFilter = async (queryParams) => {
  logRequest('P/D', 'calculatePremiumDiscountByFilter', queryParams)

  try {
    const endPoint = `${baseUrl}/calculatePremiumDiscountByFilter`

    const filter = queryStringToJson(queryParams)
    const response = await api.post(endPoint, filter)
    return response?.data
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.hviExtract = async (tabName) => {
  try {
    const tabNameQuery = `tabName=${tabName}`
    let filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `${service.lastAppliedFilter}`

    filter = queryStringToJson(filter)
    const url = `${baseUrl}/hviExtract?${tabNameQuery}`
    const response = await api.post(url, filter, {
      responseType: 'blob'
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}
function queryStringToJson(query) {
  const params = {}
  const pairs = query.split('&')
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=')

    // Se a chave for "short" e contiver ":", substitua por "%3A"
    if (key === 'sort') {
      params[key] = value.replace(':', '%3A')
    } else {
      params[key] = value
    }
  })

  return params
}

service.getAll = async (filter) => {
  try {
    filter = service.applyFixedColumnsFilter(filter)
    service.SetLastAppliedFilter(filter)
    service.runPreProcessing('read', { filter })
    const endPoint = `${baseUrl}/getWithPost`
    filter = queryStringToJson(filter)
    const response = await api.post(endPoint, filter)
    const data = Array.isArray(response.data.data)
      ? response.data.data
      : response.data
    service.runPostProcessing('read', { filter, data })
    await service.getPostProcessing(data)
    if (service.configOptions?.getPostProcessing) {
      service.configOptions.getPostProcessing(data)
    }
    return data
  } catch (e) {
    service.handleError(e)
  }
}
service.getCountVm = async (filter) => {
  logRequest(name, 'getCount')

  try {
    filter = service.applyFixedColumnsFilter(filter)
    service.SetLastAppliedFilter(filter)

    filter = queryStringToJson(filter)
    const endPointCount = `${baseUrl}/countWihPost`
    const responseCount = await api.post(endPointCount, filter)
    const count = responseCount.data
    return count
  } catch (e) {
    service.handleError(e)
    throw e
  }
}

service.getFieldValues = async (field, queryParams) => {
  service.logRequest(name, 'getFieldValues')

  const endPointValues = `${baseUrl}/filterOptions/${field}`

  const filter = queryStringToJson(queryParams)

  try {
    const response = await api.post(endPointValues, filter)

    return response.data
  } catch (e) {
    service.handleError(e)
  }
}
service.export = async (queryParams) => {
  service.logRequest(name, 'export', queryParams)

  try {
    queryParams = service.applyTabNameQuery(queryParams)
    queryParams = service.applyFixedColumnsFilter(queryParams)
    service.runPreProcessing(CrudAction.EXPORT, {
      queryParams
    })
    const endPoint = `${baseUrl}/excelWithPost`

    const filter = queryStringToJson(queryParams)
    const response = await api.post(endPoint, filter, {
      responseType: 'blob'
    })
    service.runPostProcessing(CrudAction.EXPORT, {
      queryParams,
      data: response.data
    })
    const filename = getResponseFilename(response)

    return { data: response.data, filename }
  } catch (e) {
    service.handleError(e)
    throw e
  }
}
export default service
