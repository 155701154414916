<template>
  <bc-grid
    ref="grid"
    v-bind="$attrs"
    v-on="$listeners"
    class="cg-inline-grid"
    :page-size="pageSize"
    :inline="true"
    :grid-options="options"
    :column-defs="columns"
    :custom-set-size="customSetSize"
    @cellChanged="onCellValueChanged"
    @grid-ready="onGridReady"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcGrid } from '@brain/core'
import { defaultGridOptions, gridUtils } from '@cargill/shared'
import _ from 'lodash'

import CgInlineInput from './CgInlineInput.vue'
import CgInlineCellRenderer from './CgInlineCellRenderer.vue'

export default defineComponent({
  name: 'CgInlineGrid',
  components: { BcGrid },
  props: {
    columnDefs: { type: Array, default: () => [] },
    pageSize: { type: Number, default: 5 },
    filter: { type: Object, default: () => ({}) },
    sort: { type: [Array], default: () => [] },
    page: { type: Number, default: 0 },
    validationSchema: { type: Object, default: null },
    validationResult: { type: Object, default: () => ({}) },
    target: { type: String, default: null },
    modifiedCells: { type: Object, default: () => ({}) },
    editable: { type: Boolean, default: true }
  },
  provide() {
    return {
      validationResult: this.results
    }
  },
  data() {
    return {
      isSyncingFilter: false,
      isSyncingSort: false,
      options: {
        ...defaultGridOptions,
        ...this.gridOptions,
        ...gridUtils.defaultGridOptions,
        onFilterChanged: () => {
          if (this.isSyncingFilter) return (this.isSyncingFilter = false)
          this.$emit('filter-change', this.gridApi().getFilterModel())
        },
        onSortChanged: () => {
          if (this.isSyncingSort) return (this.isSyncingSort = false)
          this.$emit('sort-change', this.gridApi().getSortModel())
        },
        onPaginationChanged: (event) => {
          if (!event.newPage) return
          this.$emit('page-change', this.gridApi().paginationGetCurrentPage())
        },
        processCellForClipboard: (params) => {
          if (typeof params.value === 'object') {
            return JSON.stringify(params.value)
          } else {
            return params.value
          }
        },
        processCellFromClipboard(params) {
          try {
            return JSON.parse(params.value)
          } catch {
            return params.value
          }
        },
        enableRangeSelection: true,
        getContextMenuItems: (params) => {
          const result = [
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            'export',
            {
              name: this.$t('application.misc.applyToAll'),
              disabled: !params?.column?.colDef?.editable,
              action: () => {
                const colDef = params.column.colDef
                const colId = params.column.colId
                const currentRowId = params.node.data.id
                params.api.forEachNode((node) => {
                  if (
                    currentRowId != node.data.id &&
                    node.data[colId] != params.value &&
                    this.validate(node.data)
                  ) {
                    this.$emit('cell-change', {
                      rowId: node.data.id,
                      field: colDef.field,
                      newValue: params.value,
                      oldValue: node.data[colId],
                      row: node.data
                    })
                    node.data[colId] = params.value
                    node.setData(node.data)
                  }
                })
              },
              icon: '<i class="fas fa-fill"></i>'
            }
          ]
          return result
        }
      },
      columns: [],
      results: {},
      hasErrors: false,
      isEditing: false,
      sortableColums: []
    }
  },
  methods: {
    onCellValueChanged(modifiedCells, event) {
      if (this.validate(event.data)) {
        this.$emit('cell-change', {
          rowId: event.node.data.id,
          field: event.colDef.field,
          newValue: event.newValue,
          oldValue: event.oldValue,
          row: event.node.data
        })
      } else {
        event.node.data[event.colDef.field] = event.oldValue
        event.node.setData(event.node.data)
      }
    },
    customSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
    },
    disableSortAndFilter(column) {
      column.sortable = false
      column.filter = false
      if (column.children) {
        column.children.forEach(this.disableSortAndFilter)
      }
    },
    restoreSortAndFilter(column, reference) {
      column.sortable = reference.sortable
      column.filter = reference.filter
      if (column.children) {
        column.children.forEach((child, i) => {
          this.restoreSortAndFilter(child, reference.children[i])
        })
      }
    },
    startEditing() {
      if (this.isEditing) return
      this.isEditing = true
      this.columns.forEach(this.disableSortAndFilter)
      this.gridApi().setColumnDefs(this.columns)
    },
    stopEditing() {
      this.results.errors = {}
      this.columns.forEach((column, i) => {
        this.restoreSortAndFilter(column, this.columnDefs[i])
      })
      this.gridApi().setColumnDefs(this.columns)
      this.gridApi().purgeServerSideCache([])
      this.grid().clearModifiedData()
      this.isEditing = false
    },
    closeGridInput() {
      this.gridApi().stopEditing()
    },
    validate(data) {
      if (!this.validationSchema) return true
      if (!this.results.errors) this.results.errors = {}

      let hasNewError = false
      const id = data.id
      const validationErrors = {}
      const formData = Object.keys(data).reduce(
        (acc, key) => ({
          ...acc,
          [key]: data[key] || data[key] === 0 ? data[key] : null
        }),
        {}
      )

      try {
        this.validationSchema.validateSync(formData, { abortEarly: false })
      } catch (e) {
        hasNewError = true
        e.inner.forEach((error) => {
          validationErrors[error.path] = [error.message]
        })
      }

      this.results.errors[id] = validationErrors
      this.hasErrors = Object.keys(this.results.errors).some(
        (k) => Object.keys(this.results.errors[k]).length > 0
      )

      return !hasNewError
    },
    reload() {
      this.gridApi().purgeServerSideCache([])
    },
    loadData() {
      this.grid()?.loadData()
    },
    onGridReady(params) {
      params.api.context.reloadTable = () => this.loadData()
    },
    prepareColumnForEditing(column) {
      const isEditable =
        !this.editable || column.editable === false ? false : true
      const isGroup = !!column.groupId
      return {
        ...column,
        headerName:
          isEditable && !isGroup ? `*${column.headerName}` : column.headerName,
        cellEditor: undefined,
        cellEditorFramework: CgInlineInput,
        cellRenderer: undefined,
        cellRendererFramework:
          column.cellRendererFramework ?? CgInlineCellRenderer,
        editable: isEditable,
        valueGetter: undefined,
        cellClass: (params) => {
          const edited = Object.keys(
            this.modifiedCells?.[params.node.data.id] || {}
          ).includes(params.colDef.field)
          return edited ? 'cell-edited' : ''
        },
        children: column.children
          ? column.children.map(this.prepareColumnForEditing)
          : undefined
      }
    },

    // Using method instead of computed ad Vue has problems with computed of dinamic refs
    grid() {
      return this.$refs.grid
    },
    gridApi() {
      return this.$refs.grid?.aggridApi
    },
    columnApi() {
      return this.gridApi()?.columnController.columnApi
    }
  },
  watch: {
    columnDefs: {
      immediate: true,
      handler() {
        this.columns = this.columnDefs.map(this.prepareColumnForEditing)
        this.gridApi()?.setColumnDefs(this.columns)
      }
    },
    filter(newFilter) {
      const oldFilter = this.gridApi().getFilterModel()
      if (!_.isEqual(newFilter, oldFilter)) {
        this.isSyncingFilter = true
        this.gridApi().setFilterModel(newFilter)
      }
    },
    sort(newSort) {
      const oldSort = this.gridApi().getSortModel()
      if (!_.isEqual(newSort, oldSort)) {
        this.isSyncingSort = true
        this.gridApi().setSortModel(newSort)
      }
    },
    page(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.gridApi().paginationGoToPage(newPage)
      }
    },
    pageSize(newPageSize, oldPageSize) {
      if (newPageSize !== oldPageSize) {
        this.gridApi().gridOptionsWrapper.setProperty(
          'cacheBlockSize',
          newPageSize
        )
        this.gridApi().serverSideRowModel.reset()
        this.gridApi().paginationSetPageSize(newPageSize)
        this.gridApi().paginationGoToPage(0)
      }
    },
    modifiedCells() {
      const updatedNodeIds = new Set(Object.keys(this.modifiedCells))
      const updatedNodes = []

      if (updatedNodeIds.size === 0) {
        this.stopEditing()
        return
      }

      this.startEditing()
      this.gridApi().forEachNode((rowNode) => {
        const rowId = String(rowNode.data.id)
        if (updatedNodeIds.has(rowId)) {
          const updatedData = Object.assign({}, rowNode.data)
          Object.keys(this.modifiedCells[rowId]).forEach((field) => {
            updatedData[field] = this.modifiedCells[rowId][field]
          })
          rowNode.setData(updatedData)
          updatedNodes.push(rowNode)
        }
      })

      this.gridApi().redrawRows({ rowNodes: updatedNodes })
    },
    validationResult() {
      this.results.errors = this.validationResult.errors
      this.gridApi().redrawRows()
    }
  }
})
</script>

<style lang="scss" scoped>
.cg-inline-grid {
  ::v-deep {
    .ag-row .cell-edited {
      background-color: #222222;
    }
    .ag-header-row:not(:first-child) .ag-header-cell,
    .ag-header-row:not(:first-child)
      .ag-header-group-cell.ag-header-group-cell-with-group {
      border-top: 2px solid #262c39;
    }

    .ag-header-row .ag-header-cell::after,
    .ag-header-row .ag-header-group-cell::after {
      height: 50% !important;
      top: 25% !important;
    }
  }
}
</style>
