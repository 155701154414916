<template>
  <div class="v-input">
    <bc-menu
      v-model="showPicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      @input="onClosePickerHandler"
    >
      <!-- Add to brain component @input="onClosePickerHandler" -->
      <template v-slot:activator="{ attrs }">
        <bc-text-field
          ref="input"
          v-bind="{ ...attrs, ...$attrs }"
          v-on="{ ...$on }"
          v-model="textValue"
          :append-icon="type === 'time' ? 'fa-clock' : 'fa-calendar-alt'"
          @click:append="onOpenPickerHandler"
          @input="onInputHandler"
          @blur="emitBlur"
          type="text"
        ></bc-text-field>
      </template>

      <!-- DateTime Picker -->
      <bc-tabs v-if="showPicker && type === 'datetime'" v-model="currentTab">
        <bc-tab href="#tab-date-picker" class="primary--text">
          <bc-icon>fa-calendar-alt</bc-icon>
        </bc-tab>

        <bc-tab href="#tab-time-picker" class="primary--text">
          <bc-icon>fa-clock</bc-icon>
        </bc-tab>
      </bc-tabs>

      <bc-tabs-items
        v-model="currentTab"
        v-if="showPicker && type === 'datetime'"
      >
        <bc-tab-item value="tab-date-picker">
          <bc-date-picker
            v-bind="datePickerProps.attrs"
            v-on="datePickerProps.on"
            v-model="datePickerValue"
            @input="currentTab = 'tab-time-picker'"
          ></bc-date-picker>
        </bc-tab-item>

        <bc-tab-item value="tab-time-picker">
          <bc-time-picker
            v-bind="timePickerProps.attrs"
            v-on="timePickerProps.on"
            v-model="timePickerValue"
            :format="clockTimeFormat"
            @click:minute="onClosePickerHandler"
            @click:hour="onSetHourHandler"
          ></bc-time-picker>
        </bc-tab-item>
      </bc-tabs-items>
      <!-- Date and Time Picker -->

      <!-- Date Picker -->
      <bc-date-picker
        v-bind="datePickerProps.attrs"
        v-on="datePickerProps.on"
        v-if="showPicker && type === 'date'"
        v-model="datePickerValue"
        @input="onClosePickerHandler"
      ></bc-date-picker>
      <!-- Date Picker -->

      <!-- Time Picker -->
      <bc-time-picker
        v-bind="timePickerProps.attrs"
        v-on="timePickerProps.on"
        v-if="showPicker && type === 'time'"
        v-model="timePickerValue"
        :format="clockTimeFormat"
        @click:minute="onClosePickerHandler"
        @click:hour="onSetHourHandler"
      ></bc-time-picker>
      <!-- Time Picker -->
    </bc-menu>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  watchEffect
} from '@vue/composition-api'
import {
  BcDatePicker,
  BcIcon,
  BcTab,
  BcTabItem,
  BcTabs,
  BcTabsItems,
  BcTimePicker
} from '@brain/core'

import {
  parseDate,
  formatDate,
  parsePickerDate,
  isValidDate,
  toDateString,
  toTimeString,
  prepareDate,
  clampTime,
  filterObjectByPrefix
} from './cgDateTimeFieldHelpers'

export default defineComponent({
  name: 'CgDateTimeField',
  components: {
    BcTimePicker,
    BcDatePicker,
    BcTabs,
    BcTab,
    BcTabItem,
    BcTabsItems,
    BcIcon
  },
  props: {
    type: {
      type: String,
      default: 'datetime',
      validator: (value) => ['date', 'time', 'datetime'].includes(value)
    },
    value: {
      type: [String, Date],
      default: ''
    },
    format: {
      type: String,
      default: undefined
    },
    clockFormat: {
      type: String,
      default: undefined
    },
    minDate: {
      type: [String, Date],
      default: undefined
    },
    maxDate: {
      type: [String, Date],
      default: undefined
    },
    defaultValue: {
      type: [String, Date],
      default: () => new Date().toISOString()
    }
  },
  setup(props, ctx) {
    const root = ctx.root
    const input = ref(null)
    const textValue = ref(null)
    const showPicker = ref(false)
    const currentTab = ref('tab-date-picker')

    const formatString = computed(() => {
      if (props.format) return props.format
      switch (props.type) {
        case 'date':
          return root.$t('core.dateFormat')
        case 'time':
          return root.$t('core.timeFormat')
        case 'datetime':
          return root.$t('core.datetimeFormat')
        default:
          return root.$t('core.dateFormat')
      }
    })

    const clockTimeFormat = computed(() => {
      if (props.clockFormat) return props.clockFormat
      return root.$t('core.clockTimeFormat')
    })

    const datePickerValue = computed({
      get() {
        if (!props.value) return toDateString(props.defaultValue)
        if (!isValidDate(props.value)) return null
        return toDateString(props.value)
      },
      set(value) {
        const date = parsePickerDate(value, timePickerValue.value)
        formatAndEmit(date)
      }
    })

    const timePickerValue = computed({
      get() {
        if (!props.value) return toTimeString(props.defaultValue)
        if (!isValidDate(props.value)) return null
        return toTimeString(props.value)
      },
      set(value) {
        const date = parsePickerDate(datePickerValue.value, value)
        formatAndEmit(date)
      }
    })

    const datePickerRange = computed(() => {
      const min =
        props.minDate && isValidDate(props.minDate)
          ? toDateString(props.minDate)
          : undefined
      const max =
        props.maxDate && isValidDate(props.maxDate)
          ? toDateString(props.maxDate)
          : undefined
      return { min, max }
    })

    const timePickerRange = computed(() => {
      const value = props.value || props.defaultValue
      const min = clampTime('min', value, props.minDate)
      const max = clampTime('max', value, props.maxDate)
      return { min, max }
    })

    const timePickerProps = computed(() => {
      const props = {
        attrs: {
          ...timePickerRange.value,
          ...filterObjectByPrefix(ctx.attrs, 'time-picker:')
        },
        on: filterObjectByPrefix(ctx.listeners, 'time-picker:')
      }
      return props
    })

    const datePickerProps = computed(() => {
      return {
        attrs: {
          ...datePickerRange.value,
          ...filterObjectByPrefix(ctx.attrs, 'date-picker:')
        },
        on: filterObjectByPrefix(ctx.listeners, 'date-picker:')
      }
    })

    const onOpenPickerHandler = () => {
      showPicker.value = true
    }

    const onClosePickerHandler = () => {
      showPicker.value = false
      currentTab.value = 'tab-date-picker'
      focusInput() // Add to brain component
    }

    // Add to brain component
    const focusInput = () => {
      input.value.$el.querySelector('input').focus()
    }

    const emitBlur = () => {
      if (showPicker.value) return // Add to brain component

      const date = parseDate(textValue.value, formatString.value)
      const formatedDate = prepareDate(date, props.type)
      ctx.emit('blur', formatedDate)
    }

    const onInputHandler = () => {
      const date = parseDate(textValue.value, formatString.value)
      formatAndEmit(date)
    }

    const onSetHourHandler = () => {
      const date = parsePickerDate(datePickerValue.value, timePickerValue.value)
      if (date !== props.value) {
        formatAndEmit(date)
      }
    }

    watchEffect(() => {
      if (isValidDate(props.value)) {
        textValue.value = formatDate(props.value, formatString.value)
      } else {
        textValue.value = props.value || ''
      }
    })

    const formatAndEmit = (date) => {
      const formatedDate = prepareDate(date, props.type)
      ctx.emit('input', formatedDate)
    }

    return {
      input,
      textValue,
      datePickerValue,
      datePickerProps,
      datePickerRange,
      timePickerValue,
      timePickerProps,
      timePickerRange,
      showPicker,
      currentTab,
      clockTimeFormat,
      onOpenPickerHandler,
      onClosePickerHandler,
      onInputHandler,
      onSetHourHandler,
      emitBlur
    }
  }
})
</script>

<style lang="scss" scoped>
.v-tabs-bar__content {
  .v-tab {
    background: #262c39;
  }
  .v-icon {
    color: white;
  }
}
</style>
