<template>
  <cg-date-time-field
    class="cg-inline-input-date-time"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </cg-date-time-field>
</template>

<script>
import Vue from 'vue'

import CgDateTimeField from './CgDateTimeField.vue'

export default Vue.extend({
  name: 'CgInlineInputDateTime',
  components: { CgDateTimeField }
})
</script>
