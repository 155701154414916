<template>
  <div
    class="cg-btn-dropdown"
    :class="{
      [`cg-btn-dropdown--${color}`]: true,
      'cg-btn-dropdown--expanded': expanded
    }"
  >
    <bc-btn v-bind="$attrs" v-on="$listeners" class="cg-btn-dropdown__default">
      <slot></slot>
    </bc-btn>
    <bc-menu
      v-model="expanded"
      offset-y
      left
      content-class="cg-btn-dropdown__menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <bc-btn
          v-on="on"
          v-bind="attrs"
          class="cg-btn-dropdown__expand"
          :class="{ 'cg-btn-dropdown__expand--expanded': expanded }"
          :ripple="false"
        >
          <bc-icon size="14">fa-chevron-down</bc-icon>
        </bc-btn>
      </template>
      <div
        class="cg-btn-dropdown__btn-options"
        :class="`cg-btn-dropdown__btn-options--${color}`"
      >
        <slot name="options"></slot>
      </div>
    </bc-menu>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BcBtn, BcIcon, BcMenu } from '@brain/core'

export default defineComponent({
  name: 'CgBtnDropdown',
  components: { BcBtn, BcIcon, BcMenu },
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data() {
    return {
      expanded: false
    }
  }
})
</script>

<style lang="scss" scoped>
.cg-btn-dropdown {
  &--primary {
    background: transparent linear-gradient(270deg, #28b6e7 0, #22d1d5 100%) 0 0
      no-repeat padding-box;
    &:hover,
    &.cg-btn-dropdown--expanded {
      background: #21a0e2;
    }
  }

  &__default {
    &#{&}#{&} {
      padding: 0 10px 0 20px !important;
    }
  }

  &__expand {
    &#{&}#{&} {
      padding: 0 20px 0 10px !important;
    }

    i {
      transform: rotate(0deg);
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    &--expanded {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &__menu {
    border-radius: 0;
    box-shadow: none;
  }

  &__btn-options {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    &--primary {
      background: #21a0e2;

      ::v-deep .v-btn.v-btn {
        &:not(:last-child) {
          border-bottom: 1px solid #24bbef;
        }
      }
    }

    ::v-deep {
      .v-btn.v-btn {
        padding: 0 30px 0 20px !important;
        background: transparent !important;
        min-width: unset;
      }
    }
  }

  ::v-deep .v-btn.v-btn {
    background: transparent !important;
    min-width: unset;
    &:before {
      content: none;
    }
  }
}
</style>
