import { render, staticRenderFns } from "./CgDateTimeField.vue?vue&type=template&id=bffc64e2&scoped=true&"
import script from "./CgDateTimeField.vue?vue&type=script&lang=js&"
export * from "./CgDateTimeField.vue?vue&type=script&lang=js&"
import style0 from "./CgDateTimeField.vue?vue&type=style&index=0&id=bffc64e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bffc64e2",
  null
  
)

export default component.exports