<template>
  <div class="cg-inline-cell-renderer">
    <template v-if="hasErrors">
      <bc-tooltip
        class="cg-inline-cell-renderer__error-tooltip"
        color="tooltip-gray"
        top
        :nudge-bottom="5"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="cg-inline-cell-renderer__error-cell"
            v-bind="attrs"
            v-on="on"
          >
            {{ value }}
          </div>
        </template>
        <div>
          <div
            class="cg-inline-cell-renderer__error-message"
            v-for="(error, i) in errors"
            :key="i"
          >
            {{ error }}
          </div>
        </div>
      </bc-tooltip>
    </template>
    <template v-else>
      {{ value }}
    </template>
  </div>
</template>

<script>
import { BcTooltip } from '@brain/ui'
import moment from 'moment'
import Vue from 'vue'

const DEFAULT_OBJECT_LABEL = 'text'
const DATE_TYPES = ['date', 'datetime', 'time']

export default Vue.extend({
  name: 'CgInlineCellRenderer',
  inject: ['validationResult'],
  components: { BcTooltip },
  computed: {
    value() {
      const value = this.params.valueFormatted || this.params.value
      if (!!value && typeof value === 'object') {
        const label = this.params.objectLabel || DEFAULT_OBJECT_LABEL
        return value[label]
      }
      if (this.isDateField) {
        return this.formatDate(value)
      }
      return value
    },
    isDateField() {
      return DATE_TYPES.includes(this.params.colDef.fieldType)
    },
    validationResults() {
      return this.validationResult || {}
    },
    errors() {
      const messages =
        this.validationResults.errors?.[this.params.node.data.id]?.[ // TODO: adjust brain
          this.params.column.colId
        ] || []

      return messages.map((error) =>
        typeof error === 'string'
          ? this.$t(error)
          : this.$t(error.key, error.args)
      )
    },
    hasErrors() {
      return this.errors.length > 0
    },
    dateFormat() {
      if (this.params.dateFormat) return this.params.dateFormat

      switch (this.params.colDef.fieldType) {
        case 'date':
          return this.$t('core.dateFormat')
        case 'time':
          return this.$t('core.timeFormat')
        case 'datetime':
          return this.$t('core.datetimeFormat')
        case 'month':
          return 'MMMM'
        case 'year':
          return 'YYYY'
        default:
          return undefined
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      if (!moment(date, true).isValid()) return date

      return moment(date).format(this.dateFormat)
    }
  }
})
</script>

<style lang="scss" scoped>
.cg-inline-cell-renderer {
  width: 100%;
  height: 100%;

  &__error-cell {
    /* TODO: rever cor com tema */
    border: 2px dashed #d2354f;
    margin: 5px -15px 2px;
    padding: 0 5px;
    height: calc(100% - 7px);
    width: calc(100% + 30px);
    line-height: 26px;
  }

  &__error-message {
    text-transform: uppercase;
  }
}
</style>
