import moment from 'moment'

export const vuetifyDateFormat = 'YYYY-MM-DD'
export const vuetifyTimeFormat = 'HH:mm'
export const vuetifyDateTimeFormat = `${vuetifyDateFormat} ${vuetifyTimeFormat}`

export const isValidDate = (date) => {
  return moment(date, true).isValid()
}

export const parseDate = (dateStr, format, strict = true) => {
  if (!dateStr) return null
  const date = moment(dateStr, format, strict)
  return date.isValid() ? date.toISOString() : dateStr
}

export const formatDate = (date, format) => {
  if (!date || !isValidDate(date)) return null

  return moment(date).format(format)
}

export const parsePickerDate = (date, time) => {
  const _date = date || moment().format(vuetifyDateFormat)
  const _time = time || moment().startOf('day').format(vuetifyTimeFormat)
  return parseDate(`${_date} ${_time}`, vuetifyDateTimeFormat)
}

export const toDateString = (date) => moment(date).format(vuetifyDateFormat)

export const toTimeString = (date) => moment(date).format(vuetifyTimeFormat)

export const prepareDate = (date, type) => {
  if (!date || !isValidDate(date)) return date

  switch (type) {
    case 'date':
      return moment(date).startOf('day').toISOString()
    default:
      return date
  }
}

export const clampTime = (mode, value, limit) => {
  const _value = moment(value)
  const _limit = moment(limit)

  if (!limit || !_limit.isValid()) return undefined
  if (!_value.isValid()) return toTimeString(_limit.toISOString())

  const shouldBeClamped =
    mode === 'min'
      ? moment(value).startOf('day').isBefore(_limit)
      : moment(value).endOf('day').isAfter(_limit)

  return shouldBeClamped ? toTimeString(_limit.toISOString()) : undefined
}

const toKebabCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const filterObjectByPrefix = (obj, prefix) => {
  const _prefix = toKebabCase(prefix)
  const attrs = Object.keys(obj || {}).reduce((result, attrName) => {
    const kebabName = toKebabCase(attrName)
    if (kebabName.startsWith(_prefix)) {
      result[kebabName.replace(_prefix, '')] = obj[attrName]
    }
    return result
  }, {})

  return attrs
}
