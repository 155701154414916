<template>
  <div class="premium-discount-meta-grid">
    <cg-inline-grid
      v-if="isGridReady"
      ref="grid"
      style="height: 390px; width: 100%"
      :layer="4"
      :mode="gridMode"
      :get-items="getItems"
      :count-items="countItems"
      :column-defs="columnDefs"
      :actions="actions"
      :selectable="false"
      :modified-cells="modifiedCells"
      :validation-schema="validationSchema"
      :validation-result="validationResult"
      :page-size="pageSize"
      :editable="editable"
      :sort="sort"
      :page="page"
      :filter="filter"
      :target="target"
      @sort-change="$emit('sort-changed', $event)"
      @page-change="$emit('page-changed', $event)"
      @filter-change="$emit('filter-changed', $event)"
      @cell-change="$emit('cell-changed', $event)"
    />
  </div>
</template>

<script>
import TakeUpPremiumDiscountManagementActionsCellRenderer from './TakeUpPremiumDiscountManagementActionsCellRenderer.vue'
import { defineComponent } from '@vue/composition-api'
import { createCrudColumns } from '@brain/core'
import { metadataHelper } from '@cargill/shared'
import _ from 'lodash'
import { CgCrudFilterSet, CgCrudFilterSetPd } from '@cargill/shared'
import { CgInlineGrid } from '../cg-inline-grid'

export default defineComponent({
  name: 'TakeUpPremiumDiscountManagementMetaGrid',
  components: { CgInlineGrid },
  props: {
    metadata: { type: Object, default: null },
    service: { type: Object, required: true },
    getItems: { type: Function, required: true },
    countItems: { type: Function, required: true },
    translate: { type: Function, required: true },
    actions: { type: Array, default: () => [] },
    pageSize: { type: Number, default: 5 },
    filter: { type: Object, default: () => ({}) },
    sort: { type: [Object, Array], default: () => [] },
    page: { type: Number, default: 0 },
    target: { type: String, default: null },
    validationSchema: { type: Object, default: null },
    validationResult: { type: Object, default: () => ({}) },
    modifiedCells: { type: Object, default: () => ({}) },
    editable: { type: Boolean, default: false }
  },
  data() {
    return {
      isGridReady: false,
      columnDefs: [],
      gridMode: 'serverSide'
    }
  },
  methods: {
    // Metadata manipulation
    async buildColumDefinitions(metadata) {
      if (!metadata) {
        this.columnDefs = []
        return
      }
      const processedMetadata = await metadataHelper.buildMetadata(
        metadata,
        this.service
      )
      
      processedMetadata.fields.forEach((field) => {
        if (field.gridTarget && field.gridTarget !== this.target) {
          field.suppressColumnsToolPanel = true
          field.hide = true
        }
        if(field.filterFramework == CgCrudFilterSet){
          field.filterFramework = CgCrudFilterSetPd
        }
      })
      const columns = createCrudColumns(
        processedMetadata,
        this.gridMode,
        this.translate
      ).filter((x) => x.key != 'id')
      this.cleanCellRendererFramework(columns)
      metadataHelper.defineCellRendererPremiumDiscount(
        columns,
        TakeUpPremiumDiscountManagementActionsCellRenderer
      )
      this.isGridReady = true
      this.defineUniqueGroupId(columns)
      this.columnDefs = this.groupColumns(columns)
    },
    cleanCellRendererFramework(columns) {
      for (const column of columns) {
        delete column['cellRendererFramework']
        if (column.children) {
          this.cleanCellRendererFramework(column.children)
        }
      }
    },
    defineUniqueGroupId(columns) {
      for (const column of columns.filter((x) => !_.isEmpty(x.children))) {
        if (!_.isEmpty(column.groupId)) {
          for (const child of column.children.filter(
            (x) => !_.isEmpty(x.groupId)
          )) {
            child.groupId = [column.groupId, child.groupId].join('_')
          }
        }
        this.defineUniqueGroupId(column.children)
      }
    },
    groupColumns(columns) {
      let flattenColumns = _.cloneDeep(columns)
        .map((column) => this.flatGroups(column))
        .flat()
      const maxDepth = flattenColumns
        .filter((c) => !c.hide)
        .reduce((max, c) => Math.max(max, c.groups.length), 0)

      let groupIds = 0
      let newColumns = flattenColumns
      for (let depth = 1; depth <= maxDepth; depth++) {
        let previousDummyGroup = null
        const tempColumns = []
        newColumns.forEach((column) => {
          const group = column.groups[maxDepth - depth]
          if (!group) {
            if (!previousDummyGroup) {
              const dummyGroup = {
                headerName: '',
                groupId: `dum_${++groupIds}`,
                children: [column],
                groups: column.groups.slice(maxDepth - depth - 1)
              }
              tempColumns.push(dummyGroup)
              previousDummyGroup = dummyGroup
            } else {
              previousDummyGroup.children.push(column)
            }
          } else {
            previousDummyGroup = null
            let existingGroup = tempColumns.find(
              (c) => c.groupId === group.groupId
            )
            if (!existingGroup) {
              group.children = []
              existingGroup = group
              tempColumns.push(group)
            }
            existingGroup.children.push(column)
          }
          column.groups = undefined
        })
        newColumns = tempColumns
      }

      return newColumns
    },
    flatGroups(column, groups = []) {
      if (column.groupId) {
        const group = {
          headerName: column.headerName,
          groupId: column.groupId,
          groups
        }
        return column.children.reduce(
          (columns, child) => [
            ...columns,
            ...this.flatGroups(child, [...groups, group])
          ],
          []
        )
      }
      return [{ ...column, groups }]
    },
    reload() {
      this.$refs.grid.reload()
    }
  },
  watch: {
    metadata: {
      immediate: true,
      handler(metadata) {
        this.buildColumDefinitions(_.cloneDeep(metadata))
      }
    }
  }
})
</script>
