<template>
  <bc-autocomplete
    class="cg-inline-autocomplete"
    autofocus
    :value="value"
    :items="items"
    :loading="loading"
    :return-object="returnObject"
    :clearable="clearable"
    :clear-icon="clearIcon"
    @input="$emit('input', $event)"
    @blur="$emit('blur', $event)"
  >
  </bc-autocomplete>
</template>

<script>
import Vue from 'vue'
import { BcAutocomplete } from '@brain/core'

export default Vue.extend({
  name: 'CgInlineInputAutocomplete',
  components: { BcAutocomplete },
  props: {
    options: { default: () => [], type: [Array, Function] },
    type: { default: 'select', type: String },
    itemData: { default: () => ({}), type: Object },
    value: { type: [String, Array, Object], default: null },
    returnObject: { type: Boolean, default: true },
    clearable: { type: Boolean, default: false },
    clearIcon: { type: String, default: 'fa-times' }
  },
  data() {
    return {
      items: [],
      loading: false
    }
  },
  watch: {
    options: {
      immediate: true,
      async handler() {
        if (typeof this.options === 'function') {
          this.loading = true
          this.items = await this.options({ itemData: this.itemData })
          this.loading = false
        } else {
          this.items = this.options
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.cg-inline-autocomplete {
  &::v-deep.v-select .v-input__slot {
    input {
      padding-left: 19px !important;
    }
  }
}
</style>
